//admin footer 检测权限在这。
import React ,{ useEffect } from 'react'
import { Footer } from 'antd/lib/layout/layout';
import { Image} from 'antd';
export default function AFooter() {

  return (
    <div><Footer style={{ textAlign: 'center' }}>
      
      Copyright © 2023.Association of Global Intelligent Science and Technology.<br/>
      Use of this website signifies your agreement to the AGIST Terms and Conditions.<br/>
      AGIST is a technical professional organization committed to advancing technology for the betterment of humanity.
      </Footer></div>
  )
}
