//首页
import React from 'react'
import { Card ,Col, Row,Image,Typography,Divider,Carousel,Button} from 'antd'
import './index.css'

import {Link} from 'react-router-dom'
import {
  RightSquareTwoTone,
  LeftSquareTwoTone,
  FundViewOutlined,
  HomeOutlined,
  PhoneOutlined,
  PrinterOutlined,
  MailOutlined 
} from '@ant-design/icons';
const { Title } = Typography;
export default function Home() {

  return (
    <Card type="ncenter" >
      <Row>
        <Col span={24} offset={0}>
        <Carousel 
        arrows
        prevArrow={<LeftSquareTwoTone />}
        nextArrow={<RightSquareTwoTone />}
         dotPosition={'right'} effect="fade" autoplay>

        <div>
        <Link to="/about">
        <Image
        preview={false}
        width={1200}
        height={389}
              src={require('../../imgs/home1.jpg')}
            />
          </Link>
        </div>
        
        <div>
        <Link to="/membership">
        <Image
        preview={false}
        width={1200}
        height={389}
              src={require('../../imgs/home2.jpg')}
            />
            </Link>
        </div>
       {/* <div>
        <a href="https://agist.org/iccsi2024/">
        <Image
        preview={false}
        width={1200}
        height={389}
              src={require('../../imgs/home3.jpg')}
            />
            </a>
  </div>*/}
        
        </Carousel>

        </Col>
        {/*
        <Col span={7} offset={0}>
        <Card style={{ height: '500px' }}bodyStyle={{ fontSize: '20px' }} type="inner" title={<div style={{ textAlign: 'center', fontSize: '20px' }}>AGIST Highlights</div>}>
        <br/>
          <Row align='middle'>
            <Col span={8}><Image
            preview={false}
            width={80}
            height={80}
            src={require('../../imgs/earth.jpeg')}
            />
            </Col>
            <Col align='middle' span={16}>
            DIVERSE BOARD OF GOVERNORS FROM ACROSS THE GLOB
            </Col>
          </Row>
          <br/>
          <Row align='middle'>
            <Col span={8}><Image
            preview={false}
            width={80}
            height={80}
            src={require('../../imgs/member.jpg')}
            />
            </Col>
            <Col align='middle' span={16}>
            73 MEMBERSHIP AROUND THE WORLD
            </Col>
          </Row>
          <br/>
          <Row align='middle'>
            <Col span={8}><Image
            preview={false}
            width={80}
            height={80}
            src={require('../../imgs/pub.jpg')}
            />
            </Col>
            <Col align='middle' span={16}>
            MANY ARTICLES PUBLISHED IN 2021 AND 2022
            </Col>
          </Row>
        
        
        </Card>
        </Col>
        */}
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
      <Col span={8}>
        <Card style={{ height: '450px' }} title={<div style={{ textAlign: 'center', fontSize: '20px' }}>Conferences </div>}>
        <Image
        preview={false}

              src={require('../../imgs/2.jpg')}
            />
          <a href="/CPSI2025/"><Button type="link"  icon={<RightSquareTwoTone />}/>CPSI 2025</a> 
          <br/>
          <a href="/iccsi2024/"><Button type="link"  icon={<RightSquareTwoTone />}/>ICCSI 2024</a> 
          <br/>
          <a href="/iccsi2023/"><Button type="link"  icon={<RightSquareTwoTone />}/>ICCSI 2023</a> 
          <br/>
          <a href="/iccsi2022/"><Button type="link"  icon={<RightSquareTwoTone />}/>ICCSI 2022</a> 
          <br/>
          <a href="/iccsi2021/"><Button type="link"  icon={<RightSquareTwoTone />}/>ICCSI 2021</a>
          <br/>
        </Card>
      </Col>
      <Col span={8}>
        <Card style={{ height: '450px' }} title={<div style={{ textAlign: 'center', fontSize: '20px' }}>Workshops and Training</div>}>
        <Image
        preview={false}

              src={require('../../imgs/3.jpg')}
            />
          <br/>

            <a href="/iccsi2023/autumnSchoolCFP.html" ><Button type="link"  icon={<RightSquareTwoTone />}/>2023 AUTUMN SCHOOL</a>
        <br/>
         <a href="/iccsi2022/autumnSchoolCFP.html" ><Button type="link"  icon={<RightSquareTwoTone />}/>2022 AUTUMN SCHOOL</a>
         <br/>
         <a href="/iccsi2021/winterSchoolCFP.html" ><Button type="link"  icon={<RightSquareTwoTone />}/>2021 WINTER SCHOOL</a>
        </Card>
        
      </Col>
      <Col span={8}>
        <Card style={{ height: '450px' }} title={<div style={{ textAlign: 'center', fontSize: '20px' }}>Publications</div>}>
        <Image
        preview={false}

              src={require('../../imgs/4.jpg')}
            />
            <a href="http://cpsi.agist.org" ><Button type="link"  icon={<RightSquareTwoTone />}/>Journal of Cyber-Physical-Social Intelligence</a>
            <a href="http://journalcc.net" ><Button type="link"  icon={<RightSquareTwoTone />}/>Journal of Command and Control</a>
        </Card>
      </Col>
    </Row>
          <Divider />
      <Card  style={{ marginTop: 16}}
        type="inner" title={<div style={{ textAlign: 'center', fontSize: '20px' }}>ICCSI 2024 Conferences ends</div>}>
          <Row align='middle'>
      <Col align='justify' span={10} offset={0}>
      
                <br/>
          <Link to="/news/iccsi2024">
          <Button type="primary"  icon={<RightSquareTwoTone />}> SEE MORE</Button>
          </Link>
          </Col>
          <Col align='justify' span={10} offset={2}>
            {/* <Image
        width={500}
        height={200}
        preview={false}
        src={require('../../imgs/ICCSI2023.jpg')}/> */}
          </Col>
      </Row>
        </Card>

  </Card>

  )
    
  
}
