import React, { Component ,lazy,Suspense} from 'react';
import { Layout,BackTop,Card} from 'antd';
import {
  Routes,
  Route,
} from 'react-router-dom'

import './App.css' 

import NotFound from './NotFound';
import Loading from './components/Loading';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './contents/Home';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
const About= lazy(()=> import('./contents/About'))
const Membership= lazy(()=> import('./contents/Membership'))
const Membershipreg= lazy(()=> import('./contents/Membershipreg'))
const MCheckout=lazy(()=> import('./contents/MCheckout'))
const News=lazy(()=> import('./contents/News'))
const Contact=lazy(()=> import('./contents/Contact'))
const ICCSICheckout=lazy(()=> import('./contents/ICCSICheckout'))
const ICCSIREG=lazy(()=> import('./contents/ICCSIreg'))
const { Content} = Layout;
export default class App extends Component {
  render() {
    return (
      <div  >


<PayPalScriptProvider options={{"clientId":"AUI9Am98jX9Zx938aMKooTPrLVeOY9ICfid6ubytQkUbR2E5lZSUGq_kx4sr9F_6MMUoVKuzbb4IrGMv"}}> 
    
      <Layout style={{ minHeight: '100vh' }}>
        <Layout className="site-layout">
          <Header />
          <div className='middle'>
          <Content  style={{ margin: ' 16px 16px' , backgroundColor: '#ffffff'}}>
          <BackTop />
          <Suspense fallback={<Loading/>}><div className='middle2'>

          
          <Routes>
                <Route index element = {<Home/>}/> 
                <Route path="home"  element = {<Home/>}/> 
                <Route path="about"  element = {<About/>}/> 
                <Route path="membership"  element = {<Membership/>}/> 
                <Route path="membershipreg"  element = {<Membershipreg/>}/> 
                <Route path="membercheckout/:data/:money"  element = {<MCheckout/>}/> 
                <Route path="iccsicheckout"  element = {<ICCSICheckout/>}/> 
                <Route path="iccsireg"  element = {<ICCSIREG/>}/>
                <Route path="news/*"  element = {<News/>}/> 
                <Route path="contact"  element = {<Contact/>}/> 
                <Route path="*" element={<NotFound/>} />
          </Routes>
          </div>
          </Suspense>
          </Content>
          </div>
          <Footer/>
        </Layout>
      </Layout>
      </PayPalScriptProvider>
      </div>
    );
  }
}
//<PayPalScriptProvider options={{"clientId":"AUI9Am98jX9Zx938aMKooTPrLVeOY9ICfid6ubytQkUbR2E5lZSUGq_kx4sr9F_6MMUoVKuzbb4IrGMv"}}> 
//<PayPalScriptProvider options={{"clientId":"ATgZQJB0BCZN3lm7s7gX-jXOOBgV2_1iJS9pexML8uxDab8ibwnOVY6i2iKHmnWwv0nswwFwpiRTfDud"}}>
//sb-v1cca6721588@personal.example.com
//0f_t}Jk(