//未找到页面显示
import React from 'react'
import { Result, Button } from 'antd';
import { useNavigate} from 'react-router-dom';
export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="primary" onClick={()=>{ navigate(-1);}}>Back</Button>}
  />
  )
}
